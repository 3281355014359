<template>
  <div class="d-flex flex-column flex-grow-1 rounded">
    <BaseToolBar title="Visites médicales" subtitle="Liste des visites disponibles">
      <button class="btn btn-primary" v-tooltip="'Ajouter une visite'" @click="storeVm">Ajouter</button>
    </BaseToolBar>
    <div class="d-flex flex-column flex-md-row w-100">
      <div class="flex-grow-1 d-flex flex-column">
        <div v-for="vm in vms" :key="vm.id">
          <vms-form :vm="vm"></vms-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapMultiRowFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

import VmsForm from "@/components/collaborateurs/profil/forms/medical/VmsForm.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";

export default {
  components: {
    VmsForm,
    BaseToolBar
  },
  data() {
    return {
      newVm: { vm_collaborateur_id: 0 },
      feedback: {},
      lists: {}
    };
  },
  methods: {
    ...mapActions({
      _storeVm: "collaborateurs/createVm"
    }),
    storeVm: function() {
      this.feedback = {};
      this.loading = true;
      this._storeVm(this.newVm)
        //.catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    }
  },
  mounted() {
    this.newVm.vm_collaborateur_id = this.id;
  },
  computed: {
    ...mapFields("collaborateurs", ["collaborateur.id"]),
    ...mapMultiRowFields("collaborateurs", ["vms"]),
    ...mapGetters({
      data: "collaborateurs/data"
    })
  }
};
</script>
<style lang="css">
</style>